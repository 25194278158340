<template>
    <div>
        <div class="stars-header">
            <div v-for="v in [1, 2, 3, 4, 5]" :key="v" class="stars">
                <el-rate :value="v" :colors="colors" :disabled-void-color="scoreIconColor" disabled></el-rate>
            </div>
        </div>
        
        <div 
          v-for="scoreType in scoreLabelIds" :key="scoreType" 
          class="survey-score-group" v-bind:class="{'survey-score-group-no-border': scoreType == 80}">
            
            <div class="survey-score-label">
              <span>{{$t('survey.scoreLabel'+scoreType)}}</span>
            </div>
            
            <div class="survey-score-rate" v-if="surveyType == TYPE_QUATER">
              <ResultRow :scorezz="allScores[scoreType.toString()]" :itemType="scoreType" :surveyType="surveyType" :year="year" :quater="quater"></ResultRow>
            </div>
            <div class="survey-score-rate" v-if="surveyType == TYPE_REGULAR">
              <ResultRow :scorezz="allScores[scoreType.toString()]" :itemType="scoreType" :surveyType="surveyType" :company="company"></ResultRow>
            </div>
        </div>

    </div>
</template>

<script>
import {SURVEY_SCORES, SURVEY_SCORES_COLORS, SCORE_ICON_COLOR, TYPE_REGULAR, TYPE_QUATER} from '../SurveyConstants';
import ResultRow from './ResultRow.vue';

export default {
  props: ['allScores', 'surveyType',  'year', 'quater', 'company'],
  data() {
    return {
      colors:  SURVEY_SCORES_COLORS,
      scoreLabelIds: SURVEY_SCORES,
      scoreIconColor: SCORE_ICON_COLOR,
      TYPE_REGULAR: TYPE_REGULAR,
      TYPE_QUATER: TYPE_QUATER
    }
  },
  components: {
    ResultRow
  }
};
</script>

<style src="../SurveyInfo.css" scoped></style>
<style scoped>
.survey-score-group {
    width: 100%;
}
.survey-score-label {
  width: 160px;
  font-size: 14px;
  color: #828589;
  padding: 0px;
  height: 54px;
  line-height: 54px;
  /* border: 1px solid green; */
}
.survey-score-label span {
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
}
.survey-score-rate {
  width: calc(100% - 200px);
  /* border: 1px solid greenyellow; */
}

.stars {
  width: 125px;
  display: inline-block;
  margin: 0;
}
.stars-header .stars:first-child {
  margin-left: 200px;
}
</style>