<template>
    <div v-if="isAdmin">
        <admin-header pageActive="users" @event-admin-header-search="search" @event-admin-header-add="addUser"></admin-header>

        <!-- style="border: 1px solid" -->
        <div class="page-content" >
            <div class="nub-card user-card" v-loading="listLoading">
                <div>
                    <el-table class="admin-page-table" height="670"  style="width: 100%"
                        @sort-change="sortChange" :data="users" :default-sort = "{prop: 'username', order: 'ascending'}">
                        <el-table-column prop="username" :label="$t('usersForm.account')" sortable="custom" width="300"></el-table-column>
                        <el-table-column prop="nickname" :label="$t('profileForm.username')" sortable="custom" width="300"></el-table-column>
                        <el-table-column prop="company_title" :label="$t('profileForm.company')" sortable="custom" width="245"></el-table-column>
                        <el-table-column prop="post" :label="$t('profileForm.title')" sortable="custom" width="245"></el-table-column>
                        <el-table-column prop="is_active" :label="$t('usersForm.admConfirmed')" sortable="custom" width="300" >
                            <template slot-scope="scope">
                                <div v-bind:class="{ 'cell-is-true': scope.row.is_active, 'cell-is-false': !scope.row.is_active}" class="cell-img"></div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="is_admin" :label="$t('usersForm.admRole')" sortable="custom" width="250">
                            <template slot-scope="scope">
                                <div v-bind:class="{ 'cell-is-true': scope.row.is_admin == 1, 'cell-is-false': scope.row.is_admin == 0 }" class="cell-img"></div>
                            </template>
                        </el-table-column>
                        <el-table-column width="100">
                            <template slot-scope="scope">
                                <img src="@/assets/img/lkz_ico_47.svg" alt="" class="cell-img cell-manage-buttons" @click="editUser(scope.row)">
                                <img src="@/assets/img/lkz_ico_48.svg" alt="" class="cell-img cell-manage-buttons cell-img-right" @click="showDeleteUserDialog(scope.row.id)">
                            </template>
                        </el-table-column>
                    </el-table>
                </div>

                <div class="admin-page-pagination">
                    <el-pagination 
                    :total="usersTotal"
                    :page-sizes="[10, 20, 50, 500]"
                    :page-size="searchLimitDefault"
                    layout="sizes, prev, pager, next"
                    @size-change="paginationSizeChange"
                    @current-change="paginationChange">
                    </el-pagination>
                </div>
            </div>
        </div>

        <el-dialog
            :visible.sync="isShowDeleteUserDialog"
            width="527px"
            :close-on-click-modal="false"
            :show-close="false"
            center>
            <img src="@/assets/img/lkz_ico_45.svg" alt="del" class="del-dialog-img">
            <div class="del-dialog-title">{{$t('messages.confirmDelete')}}</div>
            <div slot="footer" class="dialog-footer">
                <el-button round class="del-dialog-cancel" @click="cancelDelete">{{$t('buttons.cancel')}}</el-button>
                <el-button type="primary" round @click="deleteObjectByRow">{{$t('buttons.deleteYes')}}</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import AdminHeader from "./AdminHeader.vue";
import { SERVER_SEARCH_LIMIT } from '../../config/constants';
import server from '../../api/server-model';

export default {
    computed: mapGetters(['isAdmin']), 
    data() {
        return {
            users: [],
            searchStr: '',
            //INFO: сортировка. должно совпадать с начальными настройками таблицы, что бы отображение не отличалось от сортировки
            orderBy: 'username', 
            orderByDirection: 'asc',
            //настройка pagination
            usersTotal: 0,
            searchLimitDefault: SERVER_SEARCH_LIMIT,
            currentPage: 1,
            //общие переменные для страницы
            listLoading: false,
            isShowDeleteUserDialog: false,
            objectForDelete: null
        };
    },
    methods: {
        // opened() {
        // },
        retrivaUser() {
            this.listLoading = true;
            let m = {
                searchStr: this.searchStr, 
                orderBy: this.orderBy,
                orderByDirection: this.orderByDirection,
                offset: this.currentPage,
                limit: this.searchLimitDefault
            }
            server.searchByModel('account', m, (data) => {
              this.listLoading = false;
              if(Array.isArray(data)) {
                this.users = data;
                
                if(data[0] && data[0].total_count) {
                    this.usersTotal = Number.parseInt(data[0].total_count);
                } else {
                    this.usersTotal = 0;
                }
              } 
            });
        },
        clearPagination() {
            this.usersTotal = 0;
            this.currentPage = 1;
        },
        search(searchStr ) {
            if(this.searchStr != searchStr) {
                //INFO: если так не делать, то при замене поисковой строки результат будет выдаваться со 
                //старым смещением, и есть шанс вообще ничего не показать, т.к. подходящие записи остануться 
                //за пределами указанного смещения.
                this.clearPagination(); 
            }
            this.searchStr = searchStr;
            this.retrivaUser();
        },
        paginationChange(pageNum) {
            this.currentPage = pageNum;
            this.retrivaUser();
        },
        paginationSizeChange(size) {
            this.searchLimitDefault = size;
            this.clearPagination(); 
            this.retrivaUser();
        },
        sortChange({ prop, order }) {
            if(order) {
                this.orderBy = prop;
                this.orderByDirection = order == 'ascending' ? 'asc' : 'desc';
                this.clearPagination(); 
                this.retrivaUser();
            }
        },
        addUser() {
            this.$store.commit('setGuiCurrentEditUser');
            this.$router.push('user');
        },
        editUser(user) {
            this.$store.commit('setGuiCurrentEditUser', user);
            this.$router.push('user');
        },
        showDeleteUserDialog(id) {
            this.objectForDelete = id;
            this.isShowDeleteUserDialog = true;
        },
        cancelDelete() {
            this.objectForDelete = null;
            this.isShowDeleteUserDialog = false;
        },
        deleteObjectByRow() {
            this.listLoading = true;
            server.deleteModel('account', {id: this.objectForDelete}, (isSuccess) => {
              this.listLoading = false;
              if(isSuccess) {
                  this.cancelDelete();
                  this.retrivaUser();
              }
            });
        }
    },
    created() {
      this.retrivaUser();
    },
    components: {
        'admin-header': AdminHeader,
    }
}
</script>

<style scoped>
.user-card {
    height: 700px;
}
</style>