<template>
    <SurveyAdminTable :surveyType="TYPE_REGULAR"></SurveyAdminTable>
</template>

<script>
import { TYPE_REGULAR } from './SurveyConstants';
import SurveyAdminTable from './SurveyAdminTable.vue';

export default {
    data() {
        return {
            TYPE_REGULAR: TYPE_REGULAR
        }
    },
    components: {
        SurveyAdminTable
    }
}
</script>