<template>
    <div class="subpage-header">
        <div class="page-header">{{$t('labels.adm')}}</div>
        <div class="controlls">
          <div class="research-filter">
              <div class="research-filter-block research-filter-u bold" v-bind:class="{'research-filter-active': pageActive=='users'}"
                @click="goToRoute('users')">{{$t('labels.users')}}</div>
              <div class="research-filter-block research-filter-e bold" v-bind:class="{'research-filter-active': pageActive=='empls'}"
                @click="goToRoute('empls')">{{$t('labels.command')}}</div>
              <div class="research-filter-block research-filter-r bold" v-bind:class="{'research-filter-active': pageActive=='roles'}"
                @click="goToRoute('roles')">{{$t('labels.roles')}}</div>
              <div class="research-filter-block research-filter-s bold" v-bind:class="{'research-filter-active': pageActive=='survey'}"
                @click="goToRoute('survey')">{{$t('survey.tabLabel')}}</div>
              <div class="research-filter-block research-filter-a bold" v-bind:class="{'research-filter-active': pageActive=='authlogs'}"
                @click="goToRoute('authlogs')">{{$t('authLogs.tabLabel')}}</div>
          </div>
          <div class="search-group">
              <input class="search-input" :placeholder="$t('search')" v-model="searchStr" v-bind:class="{disabled: hideControlls }" :readonly="hideControlls"  @keyup.enter="search">
              <img src="@/assets/img/lkz_ico_10.svg" alt="o" class="search-icon1 clickable" v-show="!searchStr" @click="search" v-bind:class="{disabled: hideControlls, clickable: !hideControlls}">
              <img src="@/assets/img/lkz_ico_34.svg" alt="x" class="search-icon1 clickable" v-show="searchStr" @click="searchStr = null; search()">
          </div>
          <!-- <img src="@/assets/img/lkz_ico_12.svg" alt="" class="search-icon2 search-icon-margin disabled"> -->
          <SurveyNotifier marginRight="240px" class="search-icon2 search-icon-margin"></SurveyNotifier>
          <img src="@/assets/img/lkz_ico_40.svg" alt="" class="search-icon3 search-icon-margin "  @click="add" v-bind:class="{disabled: hideControlls || isAddNewRecDisabled, clickable: !hideControlls && !isAddNewRecDisabled}" >
        </div>
    </div>
</template>

<script>
import utilites from '../../lib/utilites';
import SurveyNotifier from '../Survey/SurveyNotifier.vue';

export default {
  props: ['pageActive', 'hideControlls', 'isAddNewRecDisabled'],
    data() {
        return {
            searchStr: ''
        };
    },
    methods: {
        goToRoute(page) {
          if (this.pageActive != page) {
            utilites.tryToGoToRoute(page);
          }
        },
        search() {
          if(!this.hideControlls) {
            this.$emit('event-admin-header-search', this.searchStr);
          }
        },
        add() {
          if(!this.hideControlls && !this.isAddNewRecDisabled) {
            this.$emit('event-admin-header-add');
          }
        }
    },
    components: {
      SurveyNotifier
    },
};
</script>

<style scoped>
  .subpage-header {
    height: 50px;
  }
  .controlls {
    margin-left: 100px;
    float: left;
  }
  .research-filter {
      width: 822px;
      height: 45px;
      float: left;
      margin-top: 2px;
      border-radius: 22.5px;
      background-color: #e8ebf5;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #6377fa;
  }
  .research-filter-block {
    height: 18px;
    cursor: pointer;
    display: inline-block;
    text-align: center;
    padding: 14px 30px 14px 30px;
  }
  .research-filter-block:hover {
    color: #24c967;
  }
  .research-filter-active {
    color: #24c967;
    border-radius: 22.4px;
    border: solid 2px #24c967;
    background-color: #dff1da;
    padding: 12px 28px 12px 28px;
    cursor: default;
  }
  .research-filter-u {
    width: 111px;
  }
  .research-filter-e {
    width: 70px;
  }
  .research-filter-r {
    width: 40px;
  }
  .research-filter-a {
    width: 140px;
  }
  .research-filter-s {
    width: 160px;
  }

  .search-input {
    width: 195px;
    height: 25px;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #828589;
    background-color: #EFF4F6;
    outline: none;
    border: none;
  }
  .search-icon-margin {
    margin-left: 30px;
    margin-top: 10px;
  }
  .search-group {
    margin-left: 80px;
  }
</style>