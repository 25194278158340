<template>
    <SurveyAdminTable :surveyType="TYPE_QUATER"></SurveyAdminTable>
</template>

<script>
import { TYPE_QUATER } from './SurveyConstants';
import SurveyAdminTable from './SurveyAdminTable.vue';

export default {
    data() {
        return {
            TYPE_QUATER: TYPE_QUATER
        }
    },
    components: {
        SurveyAdminTable
    }
}
</script>