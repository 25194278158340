<template>
    <div class="survey-admin-tabs">
        <div class="survey-admin-tab survey-admin-tab-1 bold" 
            v-bind:class="{'survey-tab-active': pageActive=='Tab1'}"
            @click="setActive('Tab1')">
                <span v-bind:class="{'link-text': pageActive != 'Tab1'}">{{$t('survey.adminTab1')}}</span>
        </div>
        <div class="survey-admin-tab survey-admin-tab-2 bold" 
            v-bind:class="{'survey-tab-active': pageActive=='Tab2'}"
            @click="setActive('Tab2')">
                <span v-bind:class="{'link-text': pageActive != 'Tab2'}">{{$t('survey.adminTab2')}}</span>
        </div>
        <div class="survey-admin-tab survey-admin-tab-3 bold" 
            v-bind:class="{'survey-tab-active': pageActive=='Tab3'}"
            @click="setActive('Tab3')">
                <span v-bind:class="{'link-text': pageActive != 'Tab3'}">{{$t('survey.adminTab3')}}</span>
        </div>
        
        <div class="new-survey-request" v-show="pageActive == 'Tab2'">
            <span class="link-text" @click="isShowCreateSurveyDialog = true">{{$t('survey.createSurvey')}}</span>
        </div>

        <SurveyAdminNewQuaterDialog 
            :isShowDialog="isShowCreateSurveyDialog"
            @event-survey-admin-new-quater-dialog-close="isShowCreateSurveyDialog = false">
        </SurveyAdminNewQuaterDialog>
    </div>
</template>

<script>
import SurveyAdminNewQuaterDialog from "./SurveyAdminNewQuaterDialog.vue";

export default {
    data() {
        return {
            pageActive: 'Tab2',
            isShowCreateSurveyDialog: false
        };
    },
    methods: {
        setActive(tabName) {
            if(this.pageActive == tabName) return;

            this.pageActive = tabName;
            this.$emit('event-survey-tab-changed', tabName);
        }
    },
    components: {
        SurveyAdminNewQuaterDialog
    }
}
</script>

<style scoped>
.survey-admin-tabs {
    height: 40px;
    background-color: #EFF4F6;
    margin: 24px 0 8px 34px;
    /* border: 1px solid red; */
}

.survey-admin-tab {
    display: inline-block;
    padding: 12px 26px;
    cursor: pointer;
    text-align: center;
    /* height: 16px;
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #25272f; */
}
.survey-admin-tab:hover {
    color: #24c967!important;
}
.survey-admin-tab-1 {
    width: 100px;
}
.survey-admin-tab-2 {
    width: 140px;
}
.survey-admin-tab-3 {
    width: 100px;
}
.survey-tab-active {
    border-radius: 19px;
    box-shadow: 0px 1px 5.8px 2.2px rgba(95, 116, 245, 0.8);
    background-color: #fff;
    cursor: default;
}
.new-survey-request {
    float: right;
    padding: 12px 0;
}
</style>