export default {
    calculateYear() {
        return new Date().getFullYear();
    },
    buildYears(yearFrom, target) {
        let current = yearFrom;
        while(current >= 2015) {
            target.push(current--);
        }
    }
}