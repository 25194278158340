<template>
  <div v-loading="isLoading">
    <el-row :gutter="20">
      <!-- style="border: 1px solid red" -->
      <el-col :span="14">
        <div class="survey-info-header">{{$t('survey.headerScore')}}</div>
        <!-- {{survey.id}} -->

        <div v-for="scoreId in scoreLabelIds" :key="scoreId" 
            class="survey-score-group" v-bind:class="{'survey-score-group-no-border': scoreId == 80}">

          <div class="survey-score-label" >{{$t('survey.scoreLabel'+scoreId)}}</div>
          <div class="survey-score-rate">
            <el-rate :value="getSurveyItem(scoreId)" :colors="colors" :disabled-void-color="scoreIconColor" disabled></el-rate>
          </div>
        </div>

      </el-col>
      <!-- style="border: 1px solid blue" -->
      <el-col :span="10">
        <div class="survey-info-header">{{$t('survey.headerInfo')}}</div>

        <div class="survey-info-group" v-if="survey.survey_type == typeRegular">
          <div class="survey-info-label">{{$t('survey.labelGeosplit')}}</div>
          <div class="survey-info-text">{{survey.gs_title}}</div>
        </div>
        
        <div class="survey-info-group">
          <div class="survey-info-label">{{$t('profileForm.title')}}</div>
          <div class="survey-info-text">{{survey.job_title}}</div>
        </div>

        <div class="survey-info-group">
          <div class="survey-info-label">{{$t('profileForm.phone')}}</div>
          <div class="survey-info-text">{{survey.phone}}</div>
        </div>

        <div class="survey-info-group">
          <div class="survey-info-label">E-mail</div>
          <div class="survey-info-text">{{survey.email}}</div>
        </div>

        <div class="survey-info-group-2">
          <div class="survey-info-label">{{$t('survey.labelRecomendations')}}</div>
          <div class="survey-info-textbox" v-bind:class=" {'height-for-regular': survey.survey_type == typeRegular } ">{{survey.description}}</div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import server from '../../api/server-model';
import {VIEW_STATUS_NEW, VIEW_STATUS_VIEWED, SURVEY_SCORES, SURVEY_SCORES_COLORS, SCORE_ICON_COLOR, TYPE_REGULAR} from './SurveyConstants';

export default {
  props: ['surveyId'],
  data() {
    return {
      isLoading: false,
      survey: {},
      surveyItems: [],
      colors:  SURVEY_SCORES_COLORS,
      scoreLabelIds: SURVEY_SCORES,
      scoreIconColor: SCORE_ICON_COLOR,
      typeRegular: TYPE_REGULAR
    }
  },
  methods: {
    getSurveyItem(scoreType) {
      return this.surveyItems.find(el => el.item_type == scoreType)?.item_score;
    },
    loadInfo() {
      this.clearForm();
      this.isLoading = true;
      let isSurveyLoadFinished = false;
      let isSurveyItemsLoadFinished = false;
      server.showByModel('survey', this.surveyId, data => {
          isSurveyLoadFinished = true;
          this.setLoadFinished(isSurveyLoadFinished, isSurveyItemsLoadFinished);
          if(data?.[0]?.id) {
              this.survey = data[0];
              this.updateViewStatus();
          }
      })
      server.searchByModel('surveyItem', { survey_id: this.surveyId}, data => {
          isSurveyItemsLoadFinished = true;
          this.setLoadFinished(isSurveyLoadFinished, isSurveyItemsLoadFinished);
          if(data && Array.isArray(data)) {
              this.surveyItems = data;
          }
          
      })
    },
    setLoadFinished(isSurveyLoadFinished, isSurveyItemsLoadFinished) {
        if(isSurveyLoadFinished && isSurveyItemsLoadFinished) {
            this.isLoading = false;
        }
    },
    clearForm()  {
      this.survey = {};
      this.surveyItems = [];
    },
    updateViewStatus() {
      const id = this.survey.id; //INFO: что бы в emit передался правильный id, даже при смене просматриваемого отчёта
      if(this.survey.view_status == VIEW_STATUS_NEW) {
        server.saveModel('survey', {id: id, view_status: VIEW_STATUS_VIEWED, removeFromQueue: true}, (isSuccess) => {
          if(isSuccess) {
            this.$emit('event-survey-set-viewed', id);
            this.$store.dispatch('retrieveNewSurveyCount');
          }
        }, 'isSuccess')
      }
    }
  },
  watch: {
    'surveyId': {
        handler: function (val, old) {
          if(val && val != old) {
            this.loadInfo();
          } 
        }
    }
  }
};
</script>

<style src="./SurveyInfo.css" scoped></style>