<template>
    <div v-if="isAdmin">
        
        <AdminHeader pageActive="survey" :hideControlls="true"></AdminHeader>

        <div class="page-content" >
            
            <SurveyAdminTabs 
                @event-survey-tab-changed="tabChanged">
            </SurveyAdminTabs>

            <component v-bind:is="currentTabComponent" class="tab"></component>
            
        </div>
        
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import AdminHeader from "../Admin/AdminHeader.vue";
import SurveyAdminTabs from "./SurveyAdminTabs.vue";
import SurveyAdminTab1 from "./SurveyAdminTab1.vue";
import SurveyAdminTab2 from "./SurveyAdminTab2.vue";
import SurveyAdminTab3 from "./SurveyAdminTab3.vue";

export default {
    computed: mapGetters(['isAdmin']), 
    data() {
        return {
            currentTabComponent: 'SurveyAdminTab2',
            listLoading: false
        };
    },
    methods: {
        tabChanged(tabName) {
            this.currentTabComponent = 'SurveyAdmin'+tabName;
        }
    },
    components: {
        AdminHeader, SurveyAdminTabs, SurveyAdminTab1, SurveyAdminTab2, SurveyAdminTab3
    }
}
</script>

<style scoped>
</style>