<template>
    <div class="page-content" >
        <div class="nub-card survey-list-card" v-loading="listLoading">
            <div>
                <el-table class="admin-page-table" height="630"  style="width: 100%"
                        cell-class-name="survey-table-cell"
                        :row-class-name="isActiveRow"
                        :data="surveys"
                        @sort-change="sortChange"
                        @row-click="selectRow">

                    <el-table-column width="10">
                        <template slot-scope="scope">
                            <div class="blue-circle" 
                                v-show="scope.row.survey_status == surveyConstants.STATUS_FOR_ADMIN && scope.row.view_status == surveyConstants.VIEW_STATUS_NEW && !isActiveRow(scope)" >
                            </div>
                        </template>
                    </el-table-column>
                    
                    <!-- <el-table-column prop="id" label="id" sortable="custom"></el-table-column> -->
                    <el-table-column prop="company_name" :label="$t('profileForm.company')" sortable="custom" min-width="150"></el-table-column>
                    <el-table-column prop="user_name" :label="$t('profileForm.username')" sortable="custom" v-if="surveyType == surveyConstants.TYPE_QUATER" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="contact_name" :label="$t('survey.contactLabel')" sortable="custom" min-width="100" show-overflow-tooltip></el-table-column>

                    <el-table-column prop="period" :label="$t('survey.periodLabel')" sortable="custom" width="110" v-if="surveyType == surveyConstants.TYPE_QUATER">
                        <template slot-scope="scope">
                            {{scope.row.year}} / {{scope.row.quater}}
                        </template>
                    </el-table-column>
                    
                    <el-table-column prop="survey_status" :label="$t('survey.statusLabel')" sortable="custom" width="130" v-if="surveyType == surveyConstants.TYPE_QUATER">
                        <template slot-scope="scope">
                            <div style="width: 128px;">
                                <div class="rgv-table-icon">
                                    <img src="@/assets/img/lkz_ico_21.svg" alt="o" class="rgv-status-icon" v-if="scope.row.survey_status == surveyConstants.STATUS_FOR_USER">
                                    <img src="@/assets/img/lkz_ico_26.svg" alt="v" class="rgv-status-icon" v-if="scope.row.survey_status == surveyConstants.STATUS_FOR_ADMIN">
                                </div>
                                <div class="rgv-table-text">{{$t('survey.statusLabel'+scope.row.survey_status)}}</div>
                            </div>
                        </template>
                    </el-table-column>

                    <el-table-column prop="date_created" :label="$t('labels.date')" sortable="custom" width="120" v-if="surveyType == surveyConstants.TYPE_REGULAR">
                        <template slot-scope="scope">
                            {{ scope.row.date_created | dateFormat(true)}} 
                        </template>
                    </el-table-column>

                </el-table>
            </div>

            <div class="admin-page-pagination">
                <el-pagination 
                :total="total"
                :page-sizes="[10, 20, 50, 500]"
                :page-size="searchLimitDefault"
                layout="sizes, prev, pager, next"
                @size-change="paginationSizeChange"
                @current-change="paginationChange">
                </el-pagination>
            </div>
        </div>

        <div class="nub-card survey-info-card">
            <SurveyInfo :surveyId="selectedSurvey.id" v-show="selectedSurvey.survey_status == surveyConstants.STATUS_FOR_ADMIN" 
                @event-survey-set-viewed="setSurveyViewed">
            </SurveyInfo>
            <div v-show="selectedSurvey.survey_status == surveyConstants.STATUS_FOR_USER" class="waiting-for-response"> {{$t('survey.waitingForResponse')}} </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { SERVER_SEARCH_LIMIT } from '../../config/constants';
import server from '../../api/server-model';
import * as surveyConstants from './SurveyConstants';

import SurveyInfo from './SurveyInfo.vue';

export default {
    props: ['surveyType'],
    computed: mapGetters(['newSurveyCreated']), 
    data() {
        return {
            surveys: [],
            searchStr: '',
            //INFO: сортировка. должно совпадать с начальными настройками таблицы, что бы отображение не отличалось от сортировки
            orderBy: 'view_status, survey_status desc, last_updated', 
            orderByDirection: 'desc',
            //настройка pagination
            total: 0,
            searchLimitDefault: SERVER_SEARCH_LIMIT,
            currentPage: 1,
            //общие переменные для страницы
            listLoading: false,
            selectedSurvey: {},
            surveyConstants: surveyConstants
        };
    },
    methods: {
        opened() {
            this.retrieveData();
        },
        retrieveData() {
            this.listLoading = true;
            let m = {
                survey_type: this.surveyType,
                calcOffset: true,
                orderBy: this.orderBy,
                orderByDirection: this.orderByDirection,
                offset: this.currentPage,
                limit: this.searchLimitDefault
            }
            server.searchByModel('survey', m, (data) => {
              this.listLoading = false;
              if(Array.isArray(data)) {
                this.surveys = data;
                
                if(data[0] && data[0].total_count) {
                    this.total = Number.parseInt(data[0].total_count);
                } else {
                    this.total = 0;
                }
              } 
            });
        },
        selectRow(row) {
            this.selectedSurvey = row;
            // this.isActiveRow(row)
        },
        clearPagination() {
            this.total = 0;
            this.currentPage = 1;
        },
        paginationChange(pageNum) {
            this.currentPage = pageNum;
            this.retrieveData();
        },
        paginationSizeChange(size) {
            this.searchLimitDefault = size;
            this.clearPagination(); 
            this.retrieveData();
        },
        sortChange({ prop, order }) {
            if(order) {
                //INFO: тк у нас столбец период составной -- задаём руками сортировку по 2 полям
                if(prop == "period") {
                    if(order == 'ascending') {
                        this.orderBy = 'year, quater';
                    } else {
                        this.orderBy = 'year desc, quater desc';
                    }
                    this.orderByDirection = '';
                } else {
                    this.orderBy = prop;
                    this.orderByDirection = order == 'ascending' ? 'asc' : 'desc';
                }
                this.clearPagination(); 
                this.retrieveData();
            }
        },
        isActiveRow({row}) { 
            return this.selectedSurvey.id == row.id ? 'survey-table-selected-row' : '';
        },
        setSurveyViewed(id) {
            //делаем так, а не по текущей выделенной строке, тк могли переключиться быстрее, чем прошел апдейт просмотренной записи
            let f = this.surveys.find(el => el.id == id);
            f.view_status = surveyConstants.VIEW_STATUS_VIEWED;
        }
    },
    components: {
        SurveyInfo
    },
    watch: {
        newSurveyCreated() {
            this.retrieveData();
        }
    },
    created() {
      this.opened();
    }
}
</script>

<style src="./Survey.css" scoped></style>
<style scoped>
.rgv-status-icon {
    width: 20px;
    height: 20px;
    margin-right: 12px;
}
.rgv-table-icon {
    float: left;
    /* margin-top: 3px; */
    height: 42px;
    padding: 12px 0;
}
.rgv-table-text {
    float: left;
    height: 42px;
    padding: 10px 0;
}
.waiting-for-response {
    margin: 300px 0;
    width: 100%;
    text-align: center;
    font-size: 30px;
    font-weight: 300;
}
.blue-circle {
    width: 8px;
    height: 8px;
    background-color: #6377fa;
    border-radius: 50%;
    margin-left: -10px;
}
</style>