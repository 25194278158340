<template>
    <div v-if="isAdmin">
        <admin-header :hideControlls="true"></admin-header>
        <el-row class="admin-card-header-row">
            <div class="Rectangle-815"><div class="Shape-1883 clickable" @click="goBack"></div></div>
            <div class="form-label bold">{{formTitle}}</div>
        </el-row>
        <el-row>
            <el-card class="empl-info-card">
                <!--  border: 1px solid red; -->
                <el-container style="height: 690px;">
                    <el-aside width="60%"  class="aside">
                        <div class="w100 object-left">
                            <form-input-component :title="$t('rolesForm.roleName')" v-bind:value.sync="role.title" :cw="'600px'" isRequired="true"></form-input-component>
                        </div>
                        <div class="w100 object-left">
                            <div class="role-setup-label users-header bold">{{$t('rolesForm.users')}}</div>
                            <el-table class="users-table" height="450"  style="width: 90%" v-loading="isLoading"
                                @sort-change="sortChange" :data="users" :default-sort = "{prop: 'nickname', order: 'ascending'}">
                                <el-table-column prop="nickname" :label="$t('profileForm.username')" sortable="custom" width="400"></el-table-column>
                                <el-table-column prop="company_title" :label="$t('profileForm.company')" sortable="custom" width="245"></el-table-column>
                                <el-table-column prop="post" :label="$t('profileForm.title')" sortable="custom" width="245"></el-table-column>
                            </el-table>
                        </div>
                    </el-aside>
                    <el-main  width="30%" class="mside">
                        <div class="role-setup-label bold">{{$t('rolesForm.dataAccess')}}</div>
                        <!-- TODO: список компаний в виде дерева, сделать отдельным компонентом -->
                        <div class="roles-select-tree" v-if="!isRolesLoading" v-loading="isRolesLoading || isLoading">
                            <el-tree
                                ref="tree"
                                :data="roleTree" 
                                :props="defaultProps" 
                                node-key="id" 
                                :default-expanded-keys="Object.keys(rolesSelected)"
                                :default-checked-keys="Object.keys(rolesSelected)"
                                :highlight-current="true"
                                show-checkbox
                                @check="handleCheck"
                                >                                
                                <!-- TODO: добавить check-strictly когда доделаю правильное снятие галочек у родителей. 
                                            надо что бы полуустановленная галка была поставлена у родителя, если в одной 
                                            ветку сняли галку, но в другой осталась.  
                                -->
                                <!-- @check-change="handleCheckChange" -->
                                <!-- :expand-on-click-node="false" -->
                            </el-tree>
                        </div>
                    </el-main>
                </el-container>
            </el-card>
        </el-row>
        <button class="button-primary button-save" @click="save" :disabled="isLoading || !isSaveEnabled">{{$t('buttons.save')}}</button>
        <save-changes-prompt></save-changes-prompt>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import AdminHeader from "./AdminHeader.vue";
import server from '../../api/server-model';
import serverDict from '../../api/server-dict';
// import * as constants from '../../config/constants';
import FormInputComponent from "@/components/FormInputComponent.vue";
import SaveChangesPrompt from "@/components/SaveChangesPrompt.vue";
import utilites from '../../lib/utilites';

export default {
    computed: {
        isSaveEnabled() {
            if(!this.role.title) {
                return false;
            }
            return true;
        },
        ...mapGetters(['isAdmin', 'guiCurrentEditRole'])
    },
    data() {
        return {
            role: {},
            formTitle: '',
            users: [],
            //INFO: сортировка. должно совпадать с начальными настройками таблицы, что бы отображение не отличалось от сортировки
            orderBy: 'nickname', 
            orderByDirection: 'asc',
            //пагинация, тут не используется, но так как запрос стандартный для поиска пользователей...
            usersTotal: 0,
            //общие переменные для страницы
            isLoading: false,
            isRolesLoading: false,
            isRolesChanged: false, //флаг для того, что на сервере надо пересобирать роли в БД
            //ui tree
            roleTree: [],
            defaultProps: {
                children: 'children',
                label: 'title'
            },
            rolesSelected: {},
        };
    },
    methods: {
        opened() {
            if(this.guiCurrentEditRole && this.guiCurrentEditRole.id) {
                let temp = {};
                Object.assign(temp, this.guiCurrentEditRole);
                this.$set(this, 'role', temp);
                this.formTitle = this.$t('labels.role') + this.role.title;
                this.retrieveUsers();
            } else {
                this.formTitle = this.$t('labels.newRole');
            }
            this.retrieveRoles();
        }, 
        retrieveUsers() {
            this.isLoading = true;
            let m = {
                role_id: this.role.id,
                orderBy: this.orderBy,
                orderByDirection: this.orderByDirection,
                limit: 1000 
            }
            server.searchByModel('account', m, (data) => {
              this.isLoading = false;
              if(Array.isArray(data)) {
                this.users = data;
                if(data[0] && data[0].total_count) {
                    this.usersTotal = Number.parseInt(data[0].total_count);
                } else {
                    this.usersTotal = 0;
                }
                this.showTreeWithTimeout();
                // setTimeout(() => this.showTree(), 10);
              } 
            });
        },
        retrieveRoles() {
            this.isRolesLoading = true;
            serverDict.companiesTree(null, data => {
                if(data && data[0]) {
                    let temp = [{id: 'all', title: this.$t('rolesForm.allObj'), lvl: 1}];
                    data.forEach(el => {
                        if(el.lvl == 1) {
                            el.parent_id = 'all';
                        }
                        el.lvl++;
                        temp.push(el);
                    });
                    utilites.dbCompatiesToTree(this.roleTree, temp, 1);
                }
                if(this.role.id) {
                    let md = {
                        id: this.role.id,
                        method: 'searchCompToRole'
                    }
                    server.modelSpecific("role", md, (data) => {
                        if(data && data[0]) {
                            for(let key in data) {
                                this.rolesSelected[data[key].company_id] = true;
                                
                            }
                            this.isRolesLoading = false;
                            this.showTreeWithTimeout();
                        } else {
                            this.isRolesLoading = false;
                            this.showTreeWithTimeout();
                        }
                    });
                } else {
                  this.isRolesLoading = false;
                }
              });
        },
        showTreeWithTimeout(ms) {
            if(!ms) {
                ms = 10;
            }
            setTimeout(() => this.showTree(), ms);
        },
        showTree() {
            //костыльно отображение дерева 
            //IMPORTANT: надо вызывать каждый раз после перерисовки формы другими компонентами.
            //TODO: вынести это долбанное дерево в отдельный компонент, которой не будет меняться!!!
            for(let key in this.rolesSelected) {
                let treeNode = this.findNode({id:key});
                if(!treeNode) {
                    this.showTreeWithTimeout(50);
                    // setTimeout(() => this.showTree(), 50);
                } else {
                    //перерисовываем выделенные ноды у дерева компаний
                    this.setCheckedForNewSelected(treeNode);
                }
            }
        },
        goBack() {
            utilites.tryToGoToRoute(-1);
        },
        save() {
            this.isLoading = true;
            server.saveModel("role", {
                id: this.role.id,
                title: this.role.title,
                companyToRole: this.rolesSelected,
                isRolesChanged: this.isRolesChanged
            }, (data) => {
                this.isLoading = false;
                this.$store.commit('setGuiIsComponentChanged');
                if(data) {
                    this.$store.commit('addMessageInfo', this.$t('recordSaved'));
                    this.isRolesChanged = false;
                    // setTimeout(() => this.showTree(), 10);
                    if(data) {
                        if(data[0] && data[0].id && !this.role.id) {
                            this.role.id = data[0].id;
                        }
                    }                    
                } else {
                    this.$store.commit('addMessageError', this.$t('recordSavingError'));
                }
                this.showTreeWithTimeout();
            }, 'data');
        },
        sortChange({ prop, order }) {
            if(order) {
                this.orderBy = prop;
                this.orderByDirection = order == 'ascending' ? 'asc' : 'desc';
                // this.clearPagination(); 
                this.retrieveUsers();
            }
        },
        handleCheck(node) {
            let treeNode = this.findNode(node);
            this.isRolesChanged = true;
            if (this.rolesSelected[node.id]) {
                //INFO: если роль удалили из списка, то
                delete this.rolesSelected[node.id];
                //снимаем выдиление у дочерних и удаляем из rolesSelected
                this.setUncheckedChild(treeNode);
                //у родителей снимать не надо, сам компонент это отлично делает
                //из rolesSelected родителей удаляем в момент установки checked 
                //  у нижестоящего элемента (см deleteParentFromRolesSelected в
                //  else ниже). Второй раз проходить вверх по дереву не надо.
            } else {
                treeNode.checked = true;
                //INFO: если роль добавили к списку
                this.rolesSelected[node.id] = true;
                //ставим выдиление родительским и снимаем выдиление у дочерних и удаляем из rolesSelected
                this.setCheckedForNewSelected(treeNode);
                //удаляем родительские из rolesSelected
                this.deleteParentFromRolesSelected(treeNode);
            }
        },
        //УСТАНОВКА ВРУЧНУЮ полувыбранных элементов. Так делать не надо, но если понадобиться -- смотреть в эту сторону. 
        //НЕХРЕНА НЕ РАБОТАЕТ СНЯТИЕ disabled у потомков, у которых есть другие потомки. disabled снимается только у самых 
        //      последних нод.
        findNode(node) {
            return this.$refs.tree.getNode(node.id);
        },
        setCheckedForNewSelected(treeNode) {
            //ставим выдиление родительским
            this.setIndeterminateParent(treeNode);
            //снимаем выдиление у дочерних и удаляем из rolesSelected
            this.setUncheckedChild(treeNode);
        },
        setIndeterminateParent(treeNode) {
            if(treeNode.parent) {
                treeNode.parent.indeterminate = true;
                this.setIndeterminateParent(treeNode.parent);
            }
        },
        deleteParentFromRolesSelected(treeNode) {
            if(treeNode.parent && treeNode.parent.data.title) {
                if(this.rolesSelected[treeNode.parent.data.id]) {
                    delete this.rolesSelected[treeNode.parent.data.id];
                }
                this.deleteParentFromRolesSelected(treeNode.parent);
            }
        },
        setUncheckedChild(treeNode) {
            treeNode.childNodes.forEach(el => {
                el.checked = false;
                this.setUncheckedChild(el);
                if(this.rolesSelected[el.data.id]) {
                    delete this.rolesSelected[el.data.id];
                }
            });
        },
    },
    created() {
        this.opened();
    },
    components: {
        'admin-header': AdminHeader,
        'form-input-component': FormInputComponent,
        'save-changes-prompt': SaveChangesPrompt
    },
    watch: {
        'role.title': {
            handler: function () {
                this.showTreeWithTimeout();
            }
        }
    }
}
</script>

<style scoped>
    /* width: 1800px;
    margin-top: 12px;
    margin-right: 25px; */

.form-label {
    width: 500px;
    height: 23px;
    font-size: 30px;
    margin-top: 30px;
    font-weight: 300;
    color: #25272f;
    float: left;
}
.Rectangle-815 {
    width: 70px;
    height: 18px;
    margin: 18px 0 10px;
    padding: 20px 0px 20px 36px;
    float: left;
}
.Shape-1883 {
  width: 34px;
  height: 18px;
  background-image: url("../../assets/img/lkz_ico_22.svg");
  background-repeat: no-repeat;
}

.card-header {
    height: 19px;
    margin-left: 34px;
    margin-bottom: 10px;
    font-size: 20px;
    color: #25272f;
}

.empl-info-card {
  width: 1800px;
  height: 700px;
  border-radius: 20px;
  box-shadow: 0px 2px 20px 0 rgba(155, 172, 184, 0.5);
  background-color: #ffffff;
  float: left;
}

.aside {
    min-width: 1000px; 
    /* overflow: hidden; */
    /* border: 1px solid;  */
}
.mside {
    min-width: 400px; 
    /* border: 1px solid; */
}

.users-header {
    margin: 36px 0px 16px 46px!important;
}

.users-table {
    margin-left: 36px;
}

.role-setup-label {
  height: 20px;
  margin-left: 16px;
  color: #25272f;
}

.button-save {
    float: left;
    width: 149px;
    height: 50px;
    margin: 20px 0 20px 1650px;
}
.roles-select-tree {
    margin-top: 10px;
    height: 580px;
    overflow-y: auto;
    /* border: 1px solid hotpink; */
}
.admin-card-header-row {
    width: 1200px;
}
</style>