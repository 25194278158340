<template>
<div class="survey-admin-new-quater-dialog">
    <el-dialog
        :visible.sync="isShowDialog"
        width="1030px"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
        :title="$t('survey.createSurveyHeader')"
        @opened="init"
        destroy-on-close
        >

        <div class="row">
            {{$t('survey.quaterSelectLabel')}}
            <div class="quater-select">
                <el-select v-model="quater">
                    <el-option v-for="q in quaters" :key="q" :label="q" :value="q"></el-option>
                </el-select>
            </div>
            <div class="divider"></div>
            {{$t('survey.yearSelectLabel')}}
            <div class="year-select">
                <el-select v-model="year">
                    <el-option v-for="q in years" :key="q" :label="q" :value="q"></el-option>
                </el-select>
            </div>
        </div>

        <div class="row row-mt">{{$t('survey.createSurveyInfoLabel')}}</div>

        <!-- INFO: default-sort указываем что бы правильно на форме отображалось по какому полю сейчас установлена сортировка -->
        <!-- class="admin-page-table" -->
        <el-table height="560" style="width: 100%" v-loading="isLoading" 
                cell-class-name="admin-new-survey-table-cell"
                @sort-change="sortChange" :data="users" :default-sort = "{prop: 'username', order: 'ascending'}">

                <el-table-column width="24">
                    <template slot-scope="scope">
                        <el-checkbox v-model="selectedUsers[scope.row.id]" @change="rebuildSelectedUsers(scope.row)"></el-checkbox>
                    </template>
                </el-table-column>
                <el-table-column prop="username" :label="$t('usersForm.account')" sortable="custom" width="320"></el-table-column>
                <el-table-column prop="company_title" :label="$t('profileForm.company')" sortable="custom" width="320"></el-table-column>
                <el-table-column prop="nickname" :label="$t('profileForm.username')" sortable="custom" width="320"></el-table-column>

        </el-table>

        <div slot="footer" class="dialog-footer">
            <div class="users-count">
                {{$t('survey.createSurveyUsersCountLabel')}} <span class="bold"> {{usersForSurvey.length}} </span>
            </div>
            <el-button class="del-dialog-cancel" round 
                :disabled="isSaving" v-bind:class="{disabled: isSaving}"
                @click="closeDialog">
                    {{$t('buttons.cancel')}}
            </el-button>
            <el-button type="primary" round 
                :disabled="usersForSurvey.length == 0 || isSaving"
                :loading="isSaving"
                @click="createQuaterSurvey">
                    {{$t('survey.buttonSend')}}
            </el-button>
        </div>

    </el-dialog>
</div>
</template>

<script>
import server from '../../api/server-model';
import surveyHelpers from './SurveyHelpers';

export default {
    props: ['isShowDialog'],
    data() {
        return {
            quater: 1,
            quaters: [1,2,3,4],
            year: '',
            years: [],

            users: [], //список всех пользователей загруженных с сервера 
            selectedUsers: {}, //выбранные пользователи, для которых надо создавать опрос
            usersForSurvey: [], //пользователи, которые будут отправлены на сервер (тк в selectedUsers могут содержаться ключи со значением false)
            
            //INFO: сортировка. должно совпадать с начальными настройками таблицы, что бы отображение не отличалось от сортировки
            orderBy: 'username', 
            orderByDirection: 'asc',
            //общие переменные для страницы
            isLoading: true,
            isSaving: false
        };
    },
    methods: {
        init() {
            if(this.years.length == 0) {
                this.year = surveyHelpers.calculateYear();
                surveyHelpers.buildYears(this.year, this.years)
                this.loadUsers();
            }
        },
        loadUsers() {
            this.isLoading = true;
            this.users = []; //если не очищать, то не перерисовываются чекбоксы на форме
            let m = {
                notAdminsOnly: true,
                orderBy: this.orderBy,
                orderByDirection: this.orderByDirection,
                limit: 1500,
                // offset: this.currentPage,
            }
            server.searchByModel('account', m, (data) => {
              this.isLoading = false;
              if(Array.isArray(data)) {
                this.users = data;
              }
            })
        },
        sortChange({ prop, order }) {
            if(order) {
                this.orderBy = prop;
                this.orderByDirection = order == 'ascending' ? 'asc' : 'desc';
                this.loadUsers();
            }
        },
        rebuildSelectedUsers(row) {
            if(this.selectedUsers[row.id]) {
                this.usersForSurvey.push({
                    user_id: row.id,
                    user_name: row.nickname,
                    company_id: row.company_id,
                    company_name: row.company_title,
                })
            } else {
                const index = this.usersForSurvey.findIndex(el => el.id = row.id);
                this.usersForSurvey.splice(index, 1);
            }
        },
        createQuaterSurvey() {
            this.isSaving = true;
            let md = {
                method: 'createByAdmin',
                users: this.usersForSurvey,
                year: this.year,
                quater: this.quater
            }

            server.modelSpecific("survey", md, (resp) => {
                this.isSaving = false;
                if(!resp.error) {
                    this.$store.commit('addMessageInfo', this.$t('survey.adminCreateMessageSuccess') + resp.rowsCreated);
                    this.$store.commit('setNewSurveyCreated', new Date().getTime())
                    this.closeDialog();
                }
            });
        },
        clearForm() {
            this.quater = 1;
            this.year = surveyHelpers.calculateYear();
            this.usersForSurvey = [];
            this.selectedUsers = {};
        },
        closeDialog() {
            this.clearForm();
            this.$emit('event-survey-admin-new-quater-dialog-close');
        }
    }
}
</script>

<style scoped>
.quater-dialog-title {
    height: 29px;
    font-size: 30px;
    font-weight: 300;
    text-align: left;
    margin: -20px 0 46px 34px;
    color: #25272f;
}
.row {
    color: #828589;
    margin: 0 55px;
}
.row-mt {
    margin-top: 24px;
}
.year-select {
    width: 80px;
    display: inline-block;
}
.quater-select {
    width: 52px;
    display: inline-block;
}
.divider {
    display: inline-block;
    width: 16px;
    height: 16px;
}

.users-count {
    float: left;
    margin: 10px 0 0 55px;
}
</style>