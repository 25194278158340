<template>
    <!-- INFO-IMP: @closed="$emit..." для того бы сбросить значение score и сработал watch -->
    <el-dialog
        :visible.sync="isShowDialog"
        width="527px"
        destroy-on-close
        :show-close="false"
        @closed="closeDialog"
        >
            <div class="scores-company-dialog-content"> 
                <div class="loading" v-loading="isLoading" v-show="isLoading"></div>
                <p class="company" 
                    v-for="company in companies" :key="company.title">
                        {{company.title}}
                </p>
            </div>
    </el-dialog>
</template>

<script>
import { TYPE_REGULAR, TYPE_QUATER } from '../SurveyConstants';
import server from '../../../api/server-model';

export default {
    //surveyType -- тип опроса: квартальный или регулярный
    //itemType -- тип оценки: см SurveyConstants.SURVEY_SCORES (строки таблицы с оценками: 10, 20, и тд)
    //itemScore -- значение(номер) оценки от 1 до 5, например оценок 3 у нас 15 штук, значит itemScore = 3
    //scoreVal -- количество оценок, например оценок 3 у нас 15 штук, значит scoreVal = 15
    props: ['surveyType', 'itemType', 'itemScore', 'scoreVal',  'year', 'quater', 'company'],
    data() {
        return {
            isShowDialog: false,
            isLoading: true,
            companies: []
        }
    },
    methods: {
        closeDialog() {
            this.$emit('event-survey-close-company-dialog');
        },
        scoreChange() {
            this.companies = []; //делаем при открытии, а не при закрытии, те если диалог закрыли раньше, чем прилетел ответ, то при следующем открытии будет показан прошлый результат
            if(!this.scoreVal) return this.closeDialog();
            if(this.surveyType == TYPE_REGULAR && (this.company && this.company.lvl == 3)) return this.closeDialog();

            //если количество оценок = 0, то показывать диалог не надо!
            this.isShowDialog = !!this.scoreVal;
            this.loadCompanies();
        },
        loadCompanies() {
            if(this.surveyType == TYPE_REGULAR) {
                this.retrieveCompaniesForRegular()
            } else if(this.surveyType == TYPE_QUATER) {
                this.retrieveCompaniesForQuater()
            }
        },
        retrieveCompaniesForRegular() {
            const md = {
                method: 'selectCompaniesForRegular',
                item_type: this.itemType,
                item_score: this.itemScore
            }
            if(this.company && this.company.id) {
                md.companyId = this.company.id;
                md.lvl = this.company.lvl;
            }
            server.modelSpecific("surveyItem", md, data => {
                this.isLoading = false;
                if(data && Array.isArray(data)) {
                    this.companies = data;
                }
            });
        },
        retrieveCompaniesForQuater() {
            const md = {
                method: 'selectCompaniesForQuater',
                item_type: this.itemType,
                item_score: this.itemScore,
                year: this.year,
                quater: this.quater
            }
            server.modelSpecific("surveyItem", md, data => {
                this.isLoading = false;
                if(data && Array.isArray(data)) {
                    this.companies = data;
                }
            });
        }
    },
    watch: {
        'itemScore': {
            handler: function () {
                this.scoreChange();
            }
        }
    }
}
</script>

<style scoped>
.scores-company-dialog-content {
    margin-top: -45px;
    min-height: 30px;
    max-height: 500px;
    overflow-y: auto;
}
.loading {
    height: 40px;
}
.company {
    padding: 0 20px;
}
</style>