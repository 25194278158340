<template>
    <div>
        <div class="survey-info-header">{{$t('survey.adminHeaderRegular')}}</div>
        
        <div class="row">
            
            {{$t('survey.adminLabelTotal')}}
            <div class="year-select total">{{total}}</div>

            {{$t('survey.adminLabelCompany')}}
            <span @click="isShowCompanieTreeDialog=true" class="selected-company-wrapper">
                <span class="selected-company" v-show="!company.id">{{$t('researchForm.filterAll')}}</span>
                <span class="selected-company" v-show="company.id">{{company.title}}</span>
                <i class="el-select__caret el-input__icon el-icon-arrow-down"></i>
            </span>
            <CompanySelectDialog 
                :isShowCompanieTreeDialog="isShowCompanieTreeDialog" :company="company"
                @event-select-company-dialog-cancel="isShowCompanieTreeDialog = false"
                @event-select-company-dialog-select="companySelect"
                @event-select-company-dialog-clear="companyClear">
            </CompanySelectDialog>
        
        </div>

        <div class="results" v-loading="isLoading">
            <Results :allScores="data" :surveyType="TYPE_REGULAR" :company="company"></Results>
        </div>
    </div>
</template>

<script>
import server from '../../../api/server-model';
import CompanySelectDialog from "../../../components/CompanySelectDialog.vue";
import Results from './Results';

import { TYPE_REGULAR } from '../SurveyConstants';

export default {
    data() {
        return {
            data: {},
            total: 0,
            isLoading: false,
            company: {},
            isShowCompanieTreeDialog: false,
            TYPE_REGULAR: TYPE_REGULAR
        };
    },
    methods: {
        init() {
            this.loadData();
        },
        loadData() {
            this.isLoading = true;

            //запрос оценок
            let md = {
                method: 'calculateRegular',
                companyId: this.company.id 
            }
            server.modelSpecific("surveyItem", md, data => {
                this.isLoading = false;
                let temp = {};
                if(data && Array.isArray(data)) {
                    data.forEach(el => {
                        temp[el.item_type] = el.counters;
                    });
                }
                this.data = temp;
            });

            //запрос количества оценок
            md.method = 'calculateRegularCount';
            server.modelSpecific("surveyItem", md, data => {
                if(data && data[0]) {
                    this.total = data[0].count;
                }
            });

        },
        companySelect(data) {
            this.isShowCompanieTreeDialog = false;
            this.company = data;
            this.loadData();
        },
        companyClear() {
            this.isShowCompanieTreeDialog = false;
            this.company = {};
            this.loadData();
        }
        
    },
    components: {
        CompanySelectDialog, Results
    },
    created() {
        this.init();
    }
}
</script>

<style src="../SurveyInfo.css" scoped></style>
<style src="./Statistics.css" scoped></style>

<style scoped>
.selected-company-wrapper {
    cursor: pointer;
}
.selected-company {
    margin-left: 16px;
    color: #25272f;
}
</style>