<template>
    <div>
        <div v-if="scores">
            <div v-for="i in [1, 2, 3, 4, 5]" :key="i" class="score-block" @click="showScoredCompanyList(i)">
                <span>{{ getScore(i) }}</span>
                <el-progress :stroke-width="10" :percentage="calculatePercentages(i)" :color="colors[i]" :show-text="false" class="score-progress"></el-progress>
            </div>
        </div>

        <ScoresCompanyDialog 
          :surveyType="surveyType" 
          :itemType="itemType"
          :itemScore="clickedScore" 
          :scoreVal="getScore(clickedScore)"
          :year="year" 
          :quater="quater"
          :company="company"
          @event-survey-close-company-dialog="clickedScore = null">
        </ScoresCompanyDialog>
    </div>
</template>

<script>
import { SURVEY_SCORES_COLORS } from '../SurveyConstants';
import ScoresCompanyDialog from './ScoresCompanyDialog.vue';

export default {
  //INFO-IMP: scorezz и scores потому-что если нет данных, то прилетает null,
  //          а что бы отображать нули в теблице, надо, что бы был пустой
  //          объект, но тк объект переданный из родителя нельзя менять, то вот...
  //          Если вместо пустого объекта -- будет null то строка с данными 
  //          будет отображаться пустой (не пять нулей, а пустая строка)
  props: ['scorezz', 'surveyType', 'itemType',  'year', 'quater', 'company'],
  data() {
    return {
      colors:  SURVEY_SCORES_COLORS,
      max: 0,
      scores: {},
      clickedScore: null
    }
  },
  methods: {
    setScores() {
      if(this.scorezz) {
        this.scores = this.scorezz;
      } else {
        this.scores = {};
      }
    },
    getScore(key) {
      if(!this.scores) return 0;
      const val = this.scores['score_' + key];
      return val ? val : 0;
    },
    calculateMax() {
      this.max = Math.max(
          this.getScore(1), 
          this.getScore(2), 
          this.getScore(3), 
          this.getScore(4), 
          this.getScore(5)
      );
    },
    calculatePercentages(i) {
      const val = this.getScore(i);
      if(!val) return 0;
      return val * 100 / this.max;
    },
    showScoredCompanyList(i) {
      this.clickedScore = i;
    }
  },
  components: { ScoresCompanyDialog },
  watch: {
    'scorezz': {
        handler: function (val) {
          this.setScores();
          if(val) {
            this.calculateMax();
          }
        }
    }
  }
};
</script>

<style scoped>
.score-block {
    display: inline-block;
    width: 125px;
    color: #25272f;
    cursor: pointer;
    /* border: 1px solid green; */
}
.score-block span {
    width: 30px;
    display: inline-block;
    margin-left: 4px;
}
.score-progress {
    width: 60px; 
    display: inline-block;
}
</style>