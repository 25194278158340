<template>
    <div v-if="isAdmin">
        <admin-header :hideControlls="true"></admin-header>
        <el-row class="admin-card-header-row">
            <div class="Rectangle-815"><div class="Shape-1883 clickable" @click="goBack"></div></div>
            <div class="form-label">{{formTitle}}</div>
        </el-row>
        <el-row>
            <el-card class="empl-info-card">
                <div>
                    <el-row :gutter="20">
                        <el-col :span="14">
                            <el-row :gutter="20">
                                <el-col :span="12"><form-input-component :title="$t('profileForm.username')+' (РУС)'" v-bind:value.sync="user.title" :cw="inputWidth" isRequired="true"></form-input-component></el-col>
                                <el-col :span="12"><form-input-component :title="$t('profileForm.username')+' (ENG)'" v-bind:value.sync="user.title_en" :cw="inputWidth" isRequired="true"></form-input-component></el-col>    
                            </el-row>

                            <el-row :gutter="20">
                                <el-col :span="12"><form-input-component :title="$t('profileForm.title')+' (РУС)'" v-bind:value.sync="user.post" :cw="inputWidth" isRequired="true"></form-input-component></el-col>
                                <el-col :span="12"><form-input-component :title="$t('profileForm.title')+' (ENG)'" v-bind:value.sync="user.post_en" :cw="inputWidth" isRequired="true"></form-input-component></el-col>
                            </el-row>

                            <el-row :gutter="20">
                                <el-col :span="12"><form-input-component title="E-mail" v-bind:value.sync="user.email" :cw="inputWidth" isRequired="true"></form-input-component></el-col>

                            </el-row>
                            
                            <el-row :gutter="20">
                                <!-- isRequired="true" -->
                                <el-col :span="12"><form-input-component :title="$t('profileForm.phone')" v-bind:value.sync="user.phone" :cw="inputWidth" t="phone"></form-input-component></el-col>
                                <el-col :span="12">
                                    <div class="user-for-command">
                                        <img v-show="!user.is_comand_view" src="@/assets/img/lkz_ico_35.svg" alt="" class="clickable" @click="swichUserActive">
                                        <img v-show="user.is_comand_view" src="@/assets/img/lkz_ico_36.svg" alt="" class="clickable" @click="swichUserActive">
                                        <div class="user-is-active-label">{{$t('usersForm.isCommandView')}}</div>
                                    </div>
                                </el-col>
                            </el-row>
                            <el-row :gutter="20">
                                <el-col :span="12"><form-input-component :title="$t('usersForm.sort_order')" v-bind:value.sync="user.sort_order" :cw="inputWidth" t="number" maxLen="5"></form-input-component></el-col>
                                <!-- <el-col :span="12"><form-input-component :title="$t('usersForm.rating')" v-bind:value.sync="user.rating" :cw="inputWidth" t="number" maxLen="5"></form-input-component></el-col> -->
                            </el-row>
                        </el-col>

                        <el-col :span="10">
                            <div class="phtoto-label">{{$t('usersForm.photo')}}</div>
                            <!-- accept="image/*" -->
                            <el-upload v-bind:action="uploadUrl" 
                                v-bind:class="{ hide: isNewUploadButtonHidden }"
                                list-type="picture-card" 
                                :limit="1"
                                :multiple="false"
                                accept="image/png, image/jpeg, image/jpg"
                                :on-remove="handleUploadRemove" 
                                :on-success="handleUploadSuccess" 
                                :before-upload="beforeImgUpload"
                                :file-list="fileList"
                                :headers="{'x-auth-token':token}"
                                >
                                <i class="el-icon-plus"></i>
                            </el-upload>
                        </el-col>
                    </el-row>
                </div>
            </el-card>
        </el-row>
        <button class="button-primary button-save" @click="save" :disabled="isLoading || !isSaveEnabled">{{$t('buttons.save')}}</button>
        <save-changes-prompt></save-changes-prompt>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import AdminHeader from "./AdminHeader.vue";
import server from '../../api/server-model';
import serverCommon from '../../api/server-common';
import * as constants from '../../config/constants';
import FormInputComponent from "@/components/FormInputComponent.vue";
import SaveChangesPrompt from "@/components/SaveChangesPrompt.vue";
import utilites from '../../lib/utilites';

export default {
    computed: {
        isSaveEnabled() {
            //if(!this.user.title || !this.user.email || !this.user.post || !this.user.phone) {
            if(!this.user.title || !this.user.email || !this.user.post || !this.user.title_en || !this.user.post_en) {
                return false;
            }
            return true;
        },
        ...mapGetters(['isAdmin', 'guiCurrentEditEmpl'])
    },
    data() {
        return {
            user: {is_comand_view: true},
            formTitle: '',
            //общие переменные для страницы
            isLoading: false,
            inputWidth: '90%',
            employeeList: [],
            roleList: [],
            // file upload
            fileList: [],
            uploadUrl: constants.SERVER_BASE_URL + constants.SERVER_ATTACH_UPLOAD_URL,
            token: window.localStorage.getItem(constants.TOKEN_LOCALSTORAGE_NAME),
            isNewUploadButtonHidden: true,
            avatarForRemove: null
        };
    },
    methods: {
        opened() {
            if(this.guiCurrentEditEmpl && this.guiCurrentEditEmpl.id) {
                let temp = {};
                Object.assign(temp, this.guiCurrentEditEmpl);
                this.$set(this, 'user', temp);
                this.formTitle = this.$t('labels.empl') + this.user.title;
            } else {
                this.formTitle = this.$t('labels.newEmpl');
            }
            this.rebuildAttachmentsList();
        }, 
        goBack() {
            utilites.tryToGoToRoute(-1);
        },
        swichUserActive() {
            this.$store.commit('setGuiIsComponentChanged', true);
            this.$set(this.user, 'is_comand_view', !this.user.is_comand_view);
        },
        save() {
            this.isLoading = true;
            server.saveModel("employee", this.user, (data) => {
                this.$store.commit('setGuiIsComponentChanged');
                this.isLoading = false;
                if(data) {
                    this.$store.commit('addMessageInfo', this.$t('recordSaved'));
                    if(data[0] && data[0].id && !this.user.id) {
                        this.user.id = data[0].id;
                    }
                    if(this.avatarForRemove) {
                        serverCommon.attachDelete(this.avatarForRemove.id, (isSuccess) => {
                            if (isSuccess) {
                                this.avatarForRemove = null;
                                //INFO: не переносить в rebuildAttachmentsList, иначе будет считаться, что данные измененны сразу при загрузке формы
                                // this.$store.commit('setGuiIsComponentChanged', true);
                                // this.user.attach_id = null;
                                this.rebuildAttachmentsList();
                            }
                        });
                    }                 
                } else {
                    this.$store.commit('addMessageError', this.$t('recordSavingError'));
                }
            }, 'data');
        },
        beforeImgUpload(file) {
            return new Promise((resolve, reject) => {
                if(!file) {
                    return reject();
                }
                if(file.size > 5 * 1024 * 1024) {
                    this.$store.commit('addMessageWarning', this.$t('messages.fileToBig'));
                    return reject();
                }

                //проверка размера файла по ширине и высоте
                let w, h;
                let that = this;
                let img = new Image();
                var _URL = window.URL || window.webkitURL;
                var objectUrl = _URL.createObjectURL(file);
                img.onload = function () {
                    w = this.width
                    h = this.height
                    _URL.revokeObjectURL(objectUrl);

                    if(w < 320 || h < 320) {
                        that.$store.commit('addMessageWarning', that.$t('messages.pictureToSmall'));
                        return reject();
                    } else {
                        resolve();
                    }
                };
                img.src = objectUrl;
            })
        },
        //handleUploadSuccess(file, fileList) {
        handleUploadSuccess(file) {
            if (file.isSuccess && file.data && file.data[0] && file.data[0].id) {
                // this.fileList[0] = { name: fileList.name, url: constants.SERVER_BASE_URL + '/attach/showImage?imgId=' + file.data[0].id + '&t=' + this.token, id: file.data[0].id };
                this.user.attach_id = file.data[0].id;
                //INFO: не переносить в rebuildAttachmentsList, иначе будет считаться, что данные измененны сразу при загрузке формы
                this.$store.commit('setGuiIsComponentChanged', true); 
                this.rebuildAttachmentsList();
            } else {
                this.$message.warning(this.$t('messages.fileSaveError'));
            }
        },
        handleUploadRemove(file) {
            if (file.id) {
                this.user.attach_id = null;
                this.avatarForRemove = file;
                this.save()
            } else {
                this.$message.warning(this.$t('messages.fileDelError'));
            }
        },
        rebuildAttachmentsList() {
            this.fileList = [];
            if (this.user.attach_id) {
                this.fileList[0] ={id: this.user.attach_id, url: constants.SERVER_BASE_URL + constants.SERVER_ATTACH_URL + this.user.attach_id + '&t=' + this.token };
                this.isNewUploadButtonHidden = true;
            } else {
                this.fileList = [];
                this.isNewUploadButtonHidden = false;
            }
        }
    },
    created() {
        this.opened();
    },
    components: {
        'admin-header': AdminHeader,
        'form-input-component': FormInputComponent,
        'save-changes-prompt': SaveChangesPrompt
    }
}
</script>

<style scoped>
.form-label {
    width: 500px;
    height: 23px;
    font-size: 30px;
    margin-top: 30px;
    font-weight: 300;
    color: #25272f;
    float: left;
}
.Rectangle-815 {
    width: 70px;
    height: 18px;
    margin: 18px 0 10px;
    padding: 20px 0px 20px 36px;
    float: left;
}
.Shape-1883 {
  width: 34px;
  height: 18px;
  background-image: url("../../assets/img/lkz_ico_22.svg");
  background-repeat: no-repeat;
}

.card-header {
    height: 19px;
    margin-left: 34px;
    margin-bottom: 10px;
    font-size: 20px;
    color: #25272f;
}

.empl-info-card {
  width: 1770px;
  height: 570px;
  /* margin: 16px; */
  padding: 1px 30px 52px 1px;
  border-radius: 20px;
  box-shadow: 0px 2px 20px 0 rgba(155, 172, 184, 0.5);
  background-color: #ffffff;
  float: left;
}

.user-is-active {
    margin-left: 50px;
}

.user-is-active-switch {
    float: left;
}
.user-is-active-label{
  width: 256px;
  height: 16px;
  margin-left: 16px;
  font-size: 16px;
  color: #828589;
  float: left;
}

.phtoto-label {
  height: 13px;
  width: 100%;
  margin: 16px;
  font-size: 16px;
  color: #828589;
}

.button-save {
    float: left;
    width: 149px;
    height: 50px;
    margin: 20px 0 20px 1655px;
}
.zzz-checkbox {
    margin: 16px 0 16px 34px;
}
.card-empl-body {
    margin-top: 30px; 
    height: 630px; 
    overflow-y: auto;
    overflow-x: hidden;
}
.user-for-command {
  height: 15px;
  font-size: 16px;
  margin: 55px 40px;
  color: #25272f;
}

.admin-card-header-row {
    width: 1200px;
}
</style>