<template>
    <el-dialog
        :visible.sync="isShowDialog"
        width="527px"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
        center>
        <img src="@/assets/img/lkz_ico_46.svg" alt="exit" class="del-dialog-img">
        <div class="del-dialog-title">{{$t('messages.changesNotSave1')}}<br>{{$t('messages.changesNotSave2')}}</div>
        <div slot="footer" class="dialog-footer">
            <el-button round class="del-dialog-cancel" @click="cancelNavigation">{{$t('buttons.cancel')}}</el-button>
            <el-button type="primary" round @click="loseChanges">{{$t('buttons.logoutYes')}}</el-button>
        </div>
    </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import utilites from '../lib/utilites';

export default {
  // props: ['showDialog'],
  computed: {
    ...mapGetters(['guiToRouteName']),
    isShowDialog() {
      return this.guiToRouteName != null
    }
  },
  methods: {
    cancelNavigation () {
      this.$store.commit('setGuiToRouteName');
    },
    loseChanges() {
      let toRoute = this.guiToRouteName;
      this.$store.commit('setGuiIsComponentChanged');
      this.$store.commit('setGuiToRouteName');
      utilites.goToRoute(toRoute);
    }
  }
}
</script>