<template>
    <div v-if="isAdmin">
        <admin-header pageActive="authlogs" isAddNewRecDisabled="true" @event-admin-header-search="search"></admin-header>

        <div class="page-content" >
            <div class="nub-card user-card" v-loading="listLoading">
                <div>
                    <!-- INFO: default-sort указываем что бы правильно на форме отображалось по какому полю сейчас установлена сортировка -->
                    <el-table class="admin-page-table" height="670"  style="width: 100%" 
                            @sort-change="sortChange" :data="users" :default-sort = "{prop: 'last_updated', order: 'descending'}">
                        <el-table-column prop="username" :label="$t('usersForm.account')" sortable="custom" width="300"></el-table-column>
                        <el-table-column prop="nickname" :label="$t('profileForm.username')" sortable="custom" width="300"></el-table-column>
                        <el-table-column prop="company_title" :label="$t('profileForm.company')" sortable="custom" width="245"></el-table-column>
                        <el-table-column prop="ip" :label="$t('authLogs.ipLabel')" sortable="custom" width="200"></el-table-column>
                        <el-table-column :label="$t('authLogs.statusLabel')" width="270">
                            <template slot-scope="scope">
                                <div v-bind:class="{ 'cell-is-true': scope.row.record_type == 10, 'cell-is-false': scope.row.record_type != 10 }" class="cell-img one-row"></div>
                                <div class="one-row status-text">{{$t('authLogs.status'+scope.row.record_type)}}</div>
                                <!-- <div class="one-row status-text">
                                    <span v-if="scope.row.record_type == 10">{{$t('authLogs.status10')}}</span>
                                    <span v-if="scope.row.record_type == 20">{{$t('authLogs.status20')}}</span>
                                    <span v-if="scope.row.record_type == 30">{{$t('authLogs.status30')}}</span>
                                    <span v-if="scope.row.record_type == 40">{{$t('authLogs.status40')}}</span>
                                </div> -->
                            </template>
                        </el-table-column>
                        <el-table-column prop="date_created" :label="$t('authLogs.dateCreated')" width="210" sortable="custom" >
                            <template slot-scope="scope">
                                {{ scope.row.date_created | dateFormat(null, 'DD.MM.YYYY HH:mm')}} 
                            </template>
                        </el-table-column>
                        <el-table-column prop="last_updated" :label="$t('authLogs.lastUpdated')" width="210" sortable="custom" >
                            <template slot-scope="scope">
                                {{ scope.row.last_updated | dateFormat(null, 'DD.MM.YYYY HH:mm')}} 
                            </template>
                        </el-table-column>
                    </el-table>
                </div>

                <div class="admin-page-pagination">
                    <el-pagination 
                    :total="usersTotal"
                    :page-sizes="[10, 20, 50, 500]"
                    :page-size="searchLimitDefault"
                    layout="sizes, prev, pager, next"
                    @size-change="paginationSizeChange"
                    @current-change="paginationChange">
                    </el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import AdminHeader from "./AdminHeader.vue";
import { SERVER_SEARCH_LIMIT } from '../../config/constants';
import server from '../../api/server-model';

export default {
    computed: mapGetters(['isAdmin']), 
    data() {
        return {
            users: [],
            searchStr: '',
            //INFO: сортировка. должно совпадать с начальными настройками таблицы, что бы отображение не отличалось от сортировки
            orderBy: 'last_updated', 
            orderByDirection: 'desc',
            //настройка pagination
            usersTotal: 0,
            searchLimitDefault: SERVER_SEARCH_LIMIT,
            currentPage: 1,
            //общие переменные для страницы
            listLoading: false
        };
    },
    methods: {
        // opened() {
        // },
        retrieveData() {
            this.listLoading = true;
            let m = {
                searchStr: this.searchStr, 
                orderBy: this.orderBy,
                orderByDirection: this.orderByDirection,
                offset: this.currentPage,
                limit: this.searchLimitDefault
            }
            server.searchByModel('authLog', m, (data) => {
              this.listLoading = false;
              if(Array.isArray(data)) {
                this.users = data;
                
                if(data[0] && data[0].total_count) {
                    this.usersTotal = Number.parseInt(data[0].total_count);
                } else {
                    this.usersTotal = 0;
                }
              } 
            });
        },
        clearPagination() {
            this.usersTotal = 0;
            this.currentPage = 1;
        },
        search(searchStr ) {
            if(this.searchStr != searchStr) {
                //INFO: если так не делать, то при замене поисковой строки результат будет выдаваться со 
                //старым смещением, и есть шанс вообще ничего не показать, т.к. подходящие записи остануться 
                //за пределами указанного смещения.
                this.clearPagination(); 
            }
            this.searchStr = searchStr;
            this.retrieveData();
        },
        paginationChange(pageNum) {
            this.currentPage = pageNum;
            this.retrieveData();
        },
        paginationSizeChange(size) {
            this.searchLimitDefault = size;
            this.clearPagination(); 
            this.retrieveData();
        },
        sortChange({ prop, order }) {
            if(order) {
                this.orderBy = prop;
                this.orderByDirection = order == 'ascending' ? 'asc' : 'desc';
                this.clearPagination(); 
                this.retrieveData();
            }
        }
    },
    created() {
      this.retrieveData();
    },
    components: {
        'admin-header': AdminHeader,
    }
}
</script>

<style scoped>
.user-card {
    height: 700px;
}
.one-row {
    float: left;
}
.status-text {
    margin: -2px 0 0 10px;
}
</style>