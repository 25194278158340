<template>
    <div class="page-content" >
        <div class="nub-card survey-list-card">
            <Regular></Regular>
        </div>
        <div class="nub-card survey-info-card">
            <Quater></Quater>
        </div>
    </div>
</template>

<script>
import Quater from './Statistics/Quater.vue';
import Regular from './Statistics/Regular.vue';

export default {
    data() {
        return {
        };
    },
    components: {
        Quater, Regular
    }
}
</script>

<style src="./Survey.css" scoped></style>