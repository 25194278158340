<template>
    <!-- style="border: 1px solid red; height: 100%" -->
    <div>

        <div class="survey-info-header">{{$t('survey.adminHeaderQuater')}}</div>
        
        <div class="row">
            {{$t('survey.adminLabelTotal')}}
            <div class="year-select total">{{total}}</div>

            {{$t('survey.quaterSelectLabel')}}
            <div class="quater-select">
                <el-select v-model="quater" @change="loadData">
                    <el-option v-for="q in quaters" :key="q" :label="' '+q" :value="q"></el-option>
                </el-select>
            </div>

            <div class="divider"></div>
            
            {{$t('survey.yearSelectLabel')}}
            <div class="year-select">
                <el-select v-model="year" @change="loadData">
                    <el-option v-for="q in years" :key="q" :label="q" :value="q"></el-option>
                </el-select>
            </div>

            <div class="results" v-loading="isLoading">
                <Results :allScores="data" :surveyType="TYPE_QUATER" :year="year" :quater="quater"></Results>
            </div>
        </div>

    </div>
</template>

<script>
import { TYPE_QUATER } from '../SurveyConstants';
import server from '../../../api/server-model';
import surveyHelpers from '../SurveyHelpers';
import Results from './Results';

export default {
    data() {
        return {
            quater: 1,
            quaters: [1,2,3,4],
            year: '',
            years: [],
            total: 0,
            isLoading: false,
            data: {},
            TYPE_QUATER: TYPE_QUATER
        };
    },
    methods: {
        init() {
            this.year = surveyHelpers.calculateYear();
            surveyHelpers.buildYears(this.year, this.years);
            this.loadData();
        },
        loadData() {
            this.isLoading = true;
            
            //запрос оценок
            let md = {
                method: 'calculateQuater',
                year: this.year,
                quater: this.quater
            }
            server.modelSpecific("surveyItem", md, data => {
                this.isLoading = false;
                let temp = {};
                if(data && Array.isArray(data)) {
                    data.forEach(el => {
                        temp[el.item_type] = el.counters;
                    });
                }
                this.data = temp;
            });

            //запрос количества оценок
            md.method = 'calculateQuaterCount';
            server.modelSpecific("surveyItem", md, data => {
                if(data && data[0]) {
                    this.total = data[0].count;
                }
            });
        },
    },
    components: {
        Results
    },
    created() {
        this.init();
    }
}
</script>

<style src="../SurveyInfo.css" scoped></style>
<style src="./Statistics.css" scoped></style>

<style scoped>
</style>