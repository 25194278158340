<template>
    <div v-if="isAdmin">
        <admin-header :hideControlls="true"></admin-header>
        <el-row class="admin-card-header-row">
            <div class="Rectangle-815"><div class="go-back-button clickable" @click="goBack"></div></div>
            <div class="form-label">{{formTitle}}</div>
            <div class="user-is-active">
                <img v-show="!user.is_active" src="@/assets/img/lkz_ico_35.svg" alt="" class="user-is-active-switch clickable" @click="swichUserActive">
                <img v-show="user.is_active" src="@/assets/img/lkz_ico_36.svg" alt="" class="user-is-active-switch clickable" @click="swichUserActive">
                <div class="user-is-active-label">{{$t('usersForm.admConfirmed')}}</div>
            </div>
        </el-row>
        
        <el-row>
            <el-card class="user-info-card">
                <div class="card-header">{{$t('profileForm.mainLabel')}}</div>
                <div>
                    <form-input-component :title="$t('profileForm.username')" v-bind:value.sync="user.nickname" :cw="inputWidth" isRequired="true"></form-input-component>
                    <form-input-component :title="$t('profileForm.company')" v-bind:value.sync="user.company_id" :cw="inputWidth" t="company" isRequired="true" 
                        :company="{id: user.company_id, title: user.company_title, logo: user.logo}"></form-input-component>
                    <form-input-component :title="$t('profileForm.title')" v-bind:value.sync="user.post" :cw="inputWidth"></form-input-component>
                    <form-input-component :title="$t('profileForm.phone')" v-bind:value.sync="user.phone" :cw="inputWidth" t="phone"></form-input-component>
                    <form-input-component :title="$t('profileForm.login') + ' (e-mail)'" v-bind:value.sync="user.username" :cw="inputWidth" isRequired="true"></form-input-component>
                    <form-input-component :title="$t('login.pass')" v-bind:value.sync="user.password" :cw="inputWidth" :isRequired="!user.id" v-if="!user.id"></form-input-component>
                </div>
            </el-card>
            <el-card class="user-info-card">
                <div class="card-header">{{$t('labels.command')}}</div>
                <div class="card-empl-body">
                    <div v-for="empl in employeeList" :key="empl.id" class="zzz-checkbox">
                        <el-checkbox v-model="empl.etu_id">{{empl.title}}</el-checkbox>
                    </div>
                </div>
            </el-card>
            <el-card class="user-info-card">
                <div class="card-header">{{$t('labels.roles')}}</div>
                <div class="card-empl-body">
                        <div v-for="role in roleList" :key="role.id" class="zzz-checkbox">
                            <el-checkbox v-model="role.is_have_role">{{role.title}}</el-checkbox>
                        </div>
                </div>
            </el-card>
        </el-row>
        <button class="button-primary button-save" @click="save" :disabled="isLoading || !isSaveEnabled">{{$t('buttons.save')}}</button>
        <save-changes-prompt></save-changes-prompt>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AdminHeader from "./AdminHeader.vue";
import server from '../../api/server-model';
import FormInputComponent from "@/components/FormInputComponent.vue";
import SaveChangesPrompt from "@/components/SaveChangesPrompt.vue";
import utilites from '../../lib/utilites';

export default {
    computed: {
        isSaveEnabled() {
            if(!this.user.nickname || !this.user.username) {
                return false;
            }
            if(!this.user.id && !this.user.password) {
                return false; //Если новый пользователь, то пароль не должнен быть пустым. если уже существующий, то пароль меняется на отдельной форме.
            }
            if(this.user.password === '') {
                return false;
            }
            return true;
        },
        ...mapGetters(['isAdmin', 'guiCurrentEditUser', 'currentUser'])
    }, 
    data() {
        return {
            user: {is_active: false, is_password_rotten: true},
            formTitle: '',
            isChanging: false,
            //общие переменные для страницы
            isLoading: false,
            // isShowDeleteUserDialog: false,
            // objectForDelete: null
            inputWidth: '90%',
            employeeList: [],
            roleList: []
        };
    },
    methods: {
        opened() {
            if(this.guiCurrentEditUser && this.guiCurrentEditUser.id) {
                let temp = {};
                Object.assign(temp, this.guiCurrentEditUser);
                this.$set(this, 'user', temp);
                this.formTitle = this.$t('labels.user') + this.user.nickname;
            } else {
                this.formTitle = this.$t('labels.newUser');
            }
            this.retriveCommandForUser();
            this.retriveRolesForUser();
        }, 
        goBack() {
            utilites.tryToGoToRoute(-1);
        },
        swichUserActive() {
            this.$store.commit('setGuiIsComponentChanged', true);
            this.$set(this.user, 'is_active', !this.user.is_active);
        },
        save() {
            if(utilites.checkPassword(this.user.password, true)) {
                this.isLoading = true;
                let emplToUser = this.employeeList.filter(el => {
                    return el.etu_id;
                });
                let rolesToUser = this.roleList.filter(el => {
                    return el.is_have_role;
                });
                let md = {
                    user: this.user,
                    emplToUser: emplToUser,
                    rolesToUser: rolesToUser,
                    method: 'saveComplex'
                }
                server.modelSpecific("account", md, (resp) => {
                    this.isLoading = false;
                    this.$store.commit('setGuiIsComponentChanged');
                    if(resp.data) {
                        let data = resp.data;
                        this.$store.commit('addMessageInfo', this.$t('recordSaved'));
                        if(data[0] && data[0].id && !this.user.id) {
                            this.user.id = data[0].id;
                        }
                        if(this.user.id == this.currentUser.id) {
                            this.$store.dispatch('retriveCurrentUserInfo');
                        }
                    } else {
                        this.$store.commit('addMessageError', this.$t('recordSavingError'));
                    }
                }, 'full-response');
            }
        },
        retriveCommandForUser() {
            let md = {
                method: 'employeesForCommand',
                user_id: this.user.id
            }
            server.modelSpecific("employee", md, (data) => {
                if(data && data[0]) {
                    this.employeeList = data;
                }
            });
        },
        retriveRolesForUser() {
            let md = {
                method: 'accountToRole',
                user_id: this.user.id
            }
            server.modelSpecific("account", md, (data) => {
                if(data && data[0]) {
                    this.roleList = data;
                }
            });
        }
    },
    created() {
        this.opened();
    },
    components: {
        'admin-header': AdminHeader,
        'form-input-component': FormInputComponent,
        'save-changes-prompt': SaveChangesPrompt
    }
}
</script>

<style scoped>
.form-label {
    width: 500px;
    height: 30px;
    font-size: 30px;
    margin-top: 15px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #25272f;
    float: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.Rectangle-815 {
    width: 70px;
    height: 18px;
    margin: 7px 0 0;
    padding: 20px 0px 20px 36px;
    float: left;
}

.card-header {
    height: 19px;
    margin-left: 34px;
    margin-bottom: 10px;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #25272f;
}

.user-info-card {
  width: 535px;
  /* height: 700px; */
  height: 685px;
  /* margin-top: 16px; */
  margin-right: 36px;
  padding: 1px 30px 52px 1px;
  border-radius: 20px;
  box-shadow: 0px 2px 20px 0 rgba(155, 172, 184, 0.5);
  background-color: #ffffff;
  float: left;
}

.user-is-active {
    margin: 26px 0 0 0;
}

.user-is-active-switch {
    float: left;
}
.user-is-active-label{
  width: 400px;
  height: 16px;
  margin-left: 16px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #25272f;
  float: left;
}

.button-save {
    float: left;
    width: 149px;
    height: 50px;
    margin: 20px 0 20px 1630px;
}
.zzz-checkbox {
    margin: 16px 0 16px 34px;
}
.card-empl-body {
    margin-top: 30px; 
    height: 630px; 
    overflow-y: auto;
    overflow-x: hidden;
}

.admin-card-header-row {
    width: 1200px;
}
</style>